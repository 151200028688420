// src/store/slices/bugsSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { post } from '../../api/apiUtils';

export interface BugDetails {
  id: number;
  error: string;
  callstack: string;
  createdAt: string;
}

interface BugsState {
  bugs: BugDetails[];
  countLast24Hours: number;
  loading: boolean;
  error: string | null;
}

const initialState: BugsState = {
  bugs: [],
  countLast24Hours: 0,
  loading: false,
  error: null,
};

export const fetchBugs = createAsyncThunk('bugs/fetchBugs', async () => {
  const response = await post<{ bugs: BugDetails[]; countLast24Hours: number }>(
    '/admin/bugs',
    {}
  );
  return response;
});

const bugsSlice = createSlice({
  name: 'bugs',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchBugs.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBugs.fulfilled, (state, action) => {
        state.loading = false;
        state.bugs = action.payload.bugs;
        state.countLast24Hours = action.payload.countLast24Hours;
      })
      .addCase(fetchBugs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch bugs';
      });
  },
});

export default bugsSlice.reducer;
