import { Sparkles, BookText, BarChart3, Wand2 } from 'lucide-react';
import styles from './EditorialAnalysis.module.css';

const EditorialAnalysis = () => {
  return (
    <div className={styles.container}>
      {/* Hero Section */}
      <div className={styles.hero}>
        <h1 className={styles.heroTitle}>Editorial Analysis</h1>
        <p className={styles.heroSubtitle}>
          Unlock deeper insights into your writing with advanced N-gram analysis
        </p>
        {/* TODO: Carousel? */}
        {/* <div className={styles.heroImage}>
          <img
            src="/api/placeholder/800/400"
            alt="N-gram analysis dashboard"
          />
        </div> */}
      </div>

      {/* Key Features */}
      <div className={styles.features}>
        <div className={styles.featureCard}>
          <div className={styles.featureHeader}>
            <BookText className={styles.featureIcon} size={24} />
            <h3 className={styles.featureTitle}>
              Comprehensive N-gram Analysis
            </h3>
          </div>
          <p className={styles.featureDescription}>
            Analyze your text through unigrams, bigrams, and trigrams to
            understand word usage patterns and improve your writing style.
          </p>
        </div>

        <div className={styles.featureCard}>
          <div className={styles.featureHeader}>
            <BarChart3 className={styles.featureIcon} size={24} />
            <h3 className={styles.featureTitle}>Visual Insights</h3>
          </div>
          <p className={styles.featureDescription}>
            Get clear visual representations of your word usage patterns through
            interactive charts and graphs.
          </p>
        </div>

        <div className={styles.featureCard}>
          <div className={styles.featureHeader}>
            <Wand2 className={styles.featureIcon} size={24} />
            <h3 className={styles.featureTitle}>Writing Enhancement</h3>
          </div>
          <p className={styles.featureDescription}>
            Identify overused words and phrases to diversify your vocabulary and
            strengthen your writing.
          </p>
        </div>

        <div className={`${styles.featureCard} ${styles.comingSoonCard}`}>
          <div className={styles.featureHeader}>
            <Sparkles className={styles.featureIcon} size={24} />
            <h3 className={styles.featureTitle}>Character Dialogue Analysis</h3>
          </div>
          <p className={styles.featureDescription}>
            Coming soon: Advanced analysis of character-specific dialogue
            patterns and speech uniqueness.
          </p>
          <p className={styles.featureDescription}>
            Ensure each character has a distinct voice that is uniquely their
            own.
          </p>
          <div className={styles.comingSoonBadge}>Coming Soon</div>
        </div>
      </div>

      {/* How to Use Section */}
      <div className={styles.howToUse}>
        <h2 className={styles.sectionTitle}>
          Making the Most of Your N-gram Analysis
        </h2>

        <div className={styles.content}>
          <p className={styles.introText}>
            I've put together some cool insights from a deep dive into the text
            of your book. This analysis looks at how often you use certain words
            and phrases, breaking them down into single words (unigrams), pairs
            (bigrams), and trios (trigrams).
          </p>

          <div className={styles.usageGrid}>
            <div className={styles.usageItem}>
              <h3 className={styles.usageTitle}>
                1. Spotting Overused Words and Phrases
              </h3>
              <p>
                The n-gram analysis shows you which words and phrases pop up the
                most in your book. This can help you catch any "crutch" words or
                phrases you might be leaning on too much.
              </p>
            </div>

            <div className={styles.usageItem}>
              <h3 className={styles.usageTitle}>
                2. Boosting Vocabulary Variety
              </h3>
              <p>
                Seeing your most-used words and phrases can give you ideas for
                diversifying your language. Think about using synonyms or
                rephrasing common expressions.
              </p>
            </div>

            <div className={styles.usageItem}>
              <h3 className={styles.usageTitle}>
                3. Strengthening Your Themes
              </h3>
              <p>
                The frequent words and phrases can also reflect the main themes
                and motifs in your book. Check if your themes are coming through
                clearly.
              </p>
            </div>

            <div className={styles.usageItem}>
              <h3 className={styles.usageTitle}>4. Fine-Tuning Your Writing</h3>
              <p>
                Use the n-gram results during editing to spot overused phrases
                or filler words. Cutting down on these can make your writing
                tighter and more impactful.
              </p>
            </div>
          </div>

          <div className={styles.exampleContainer}>
            <img
              src="/images/woven-unigram-analysis.png"
              alt="N-gram analysis example"
              height={700}
              className={styles.exampleImage}
            />
            <p className={styles.imageCaption}>
              Example of unigram analysis on <i>Woven</i>, an unreleased novel
              from the{' '}
              <a href="https://infernalmoose.com">Infernal Moose Publishing</a>{' '}
              team.
            </p>
            <p>
              The story primarily is about Elara, so we see her prominence in
              this kind of analysis.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditorialAnalysis;
