import React, { useEffect, useState } from 'react';
import AdminDashboardLayout from '../../components/AdminDashboardLayout/AdminDashboardLayout';
import styles from './PromptManagement.module.css';
import { post } from '../../api/apiUtils';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpiner';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';

type Prompt = {
  promptName: string;
  promptString: string;
};

export const PromptManagement = () => {
  const [prompts, setPrompts] = useState<Prompt[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPrompts = async () => {
      try {
        const response = await post<{ activePrompts: [string, string][] }>(
          '/admin/prompts',
          {}
        );
        const formattedPrompts = response.activePrompts.map(
          ([promptName, promptString]) => ({
            promptName,
            promptString,
          })
        );
        setPrompts(formattedPrompts);
        setError(null);
      } catch (err) {
        setError('Failed to fetch prompts. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchPrompts();
  }, []);

  if (loading) {
    return (
      <AdminDashboardLayout>
        <div className={styles.loadingContainer}>
          <LoadingSpinner />
        </div>
      </AdminDashboardLayout>
    );
  }

  if (error) {
    return (
      <AdminDashboardLayout>
        <ErrorMessage message={error} />
      </AdminDashboardLayout>
    );
  }

  return (
    <AdminDashboardLayout>
      <div className={styles.container}>
        <h1 className={styles.title}>Prompt Management</h1>
        <div className={styles.promptGrid}>
          {prompts.map(prompt => (
            <div key={prompt.promptName} className={styles.promptCard}>
              <div className={styles.promptHeader}>
                <h2 className={styles.promptName}>{prompt.promptName}</h2>
                <div className={styles.buttonGroup}>
                  <button
                    className={styles.editButton}
                    onClick={() => {}}
                    disabled
                  >
                    Edit
                  </button>
                  <button
                    className={styles.deleteButton}
                    onClick={() => {}}
                    disabled
                  >
                    Delete
                  </button>
                </div>
              </div>
              <pre className={styles.promptString}>{prompt.promptString}</pre>
            </div>
          ))}
        </div>
      </div>
    </AdminDashboardLayout>
  );
};

export default PromptManagement;
