import React from 'react';
import styles from './ScoredSections.module.css';
import { ScoredSection } from '../../mocks/data/fileData';

interface ScoredSectionsProps {
  sections: ScoredSection[];
}

export const ScoredSections: React.FC<ScoredSectionsProps> = ({ sections }) => {
  return (
    <div className={styles.sectionsContainer}>
      {sections.map(section => (
        <div key={section.sectionName} className={styles.section}>
          <div className={styles.sectionHeader}>
            <h4 className={styles.sectionName}>{section.sectionName}</h4>
            <div
              className={styles.scoreIndicator}
              style={{
                // Set color based on score
                backgroundColor:
                  section.sectionScore >= 70
                    ? '#4ade80'
                    : section.sectionScore >= 50
                      ? '#fbbf24'
                      : '#ef4444',
              }}
            >
              {section.sectionScore.toFixed(1)}
            </div>
          </div>
          <p className={styles.sectionText}>{section.sectionText}</p>
        </div>
      ))}
    </div>
  );
};
