// src/slices/globalActionsSlice.ts
import { createSlice } from '@reduxjs/toolkit';

const globalActionsSlice = createSlice({
  name: 'globalActions',
  initialState: {},
  reducers: {
    refreshStore: () => {
      // This reducer doesn't actually do anything to the state
      // It's just a trigger for our root reducer
    },
  },
});

export const { refreshStore } = globalActionsSlice.actions;
export default globalActionsSlice.reducer;
