import React from 'react';
import styles from './CollapsibleSection.module.css';

interface CollapsibleSectionProps {
  title: string;
  children: React.ReactNode;
  isOpen: boolean;
  onToggle: () => void;
}

export const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  title,
  children,
  isOpen,
  onToggle,
}) => {
  return (
    <div className={styles.section}>
      <button className={styles.header} onClick={onToggle}>
        <h3 className={styles.title}>{title}</h3>
        <span className={`${styles.icon} ${isOpen ? styles.iconOpen : ''}`}>
          {isOpen ? '▲' : '▼'}
        </span>
      </button>
      {isOpen && <div className={styles.content}>{children}</div>}
    </div>
  );
};
