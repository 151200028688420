// src/store/slices/usageStatisticsSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { post } from '../../api/apiUtils';

interface UsageStatistics {
  totalUsers: number;
  totalOrganizations: number;
  totalBooks: number;
  totalEvaluations: number;
}

interface UsageStatisticsState extends UsageStatistics {
  loading: boolean;
  error: string | null;
}

const initialState: UsageStatisticsState = {
  totalUsers: 0,
  totalOrganizations: 0,
  totalBooks: 0,
  totalEvaluations: 0,
  loading: false,
  error: null,
};

export const fetchUsageStatistics = createAsyncThunk(
  'usageStatistics/fetch',
  async () => {
    const response = await post<UsageStatistics>('/admin/usage', {});
    return response;
  }
);

const usageStatisticsSlice = createSlice({
  name: 'usageStatistics',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchUsageStatistics.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUsageStatistics.fulfilled, (state, action) => {
        state.loading = false;
        Object.assign(state, action.payload);
      })
      .addCase(fetchUsageStatistics.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || 'Failed to fetch usage statistics';
      });
  },
});

export default usageStatisticsSlice.reducer;
