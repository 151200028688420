// src/pages/AdminDashboard.tsx
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchUsageStatistics } from '../../store/slices/usageStatisticsSlice';
import styles from './AdminDashboard.module.css';
import { useAppDispatch } from '../../store';
import AdminDashboardLayout from '../../components/AdminDashboardLayout/AdminDashboardLayout';
import { RootState } from '../../store/reducers/rootReducer';

const AdminDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    totalUsers,
    totalOrganizations,
    totalBooks,
    totalEvaluations,
    loading,
    error,
  } = useSelector((state: RootState) => state.usageStatistics);

  useEffect(() => {
    dispatch(fetchUsageStatistics());
  }, [dispatch]);

  return (
    <AdminDashboardLayout>
      <h1>Admin Dashboard</h1>
      <p>
        Welcome to the admin dashboard. Use the sidebar to navigate to different
        sections.
      </p>

      <h2>Usage Statistics</h2>
      {loading && <p>Loading statistics...</p>}
      {error && <p className={styles.error}>Error: {error}</p>}
      {!loading && !error && (
        <div className={styles.statisticsGrid}>
          <div className={styles.statisticCard}>
            <h3>Total Users</h3>
            <p>{totalUsers}</p>
          </div>
          <div className={styles.statisticCard}>
            <h3>Total Organizations</h3>
            <p>{totalOrganizations}</p>
          </div>
          <div className={styles.statisticCard}>
            <h3>Total Books</h3>
            <p>{totalBooks}</p>
          </div>
          <div className={styles.statisticCard}>
            <h3>Total Evaluations</h3>
            <p>{totalEvaluations}</p>
          </div>
        </div>
      )}
    </AdminDashboardLayout>
  );
};

export default AdminDashboard;
