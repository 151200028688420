import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { post } from '../../api/apiUtils';
import { loginSuccess } from '../../store/slices/userAuthSlice';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import FieldError from '../../components/FieldError/FieldError';
import { ApiError } from '../../api/errorHandling';
import { LoginResponse } from '../Login/Login';

export interface SignupCredentials {
  email: string;
  password: string;
}

interface FieldErrors {
  [field: string]: string[] | undefined;
}

const Signup: React.FC = () => {
  const [credentials, setCredentials] = useState<SignupCredentials>({
    password: '',
    email: '',
  });
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});
  const [unknownError, setUnknownError] = useState<string | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCredentials(prev => ({ ...prev, [name]: value }));
    setFieldErrors(prev => {
      const newErrors = { ...prev };
      delete newErrors[name];
      return newErrors;
    });
    setUnknownError(null);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setFieldErrors({});
    setUnknownError(null);
    try {
      const response = await post<LoginResponse>('/signup', credentials);
      if (!!response) {
        dispatch(loginSuccess(response.userDetails));
        navigate('/submissions');
      }
    } catch (error) {
      console.error('Signup error:', error);
      if (error instanceof ApiError) {
        console.log(
          'ApiError caught:',
          error.message,
          error.status,
          error.fieldErrors
        );
        if (error.fieldErrors) {
          const knownFields = ['email', 'password'];
          const unknownFields = Object.keys(error.fieldErrors).filter(
            field => !knownFields.includes(field)
          );

          if (unknownFields.length > 0) {
            // Handle unknown fields
            const firstUnknownField = unknownFields[0];
            setUnknownError(error.fieldErrors[firstUnknownField][0]);
          } else {
            // Handle known fields
            setFieldErrors(error.fieldErrors);
          }
        } else {
          setUnknownError(error.message);
        }
      } else {
        console.log('Unexpected error:', error);
        setUnknownError(
          'An unexpected error occurred during signup. Please try again.'
        );
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Signup</h2>
      {unknownError && <ErrorMessage message={unknownError} />}
      <div>
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={credentials.email}
          onChange={handleChange}
          required
        />
        {fieldErrors.email && <FieldError error={fieldErrors.email[0]} />}
      </div>
      <div>
        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          name="password"
          value={credentials.password}
          onChange={handleChange}
          required
        />
        {fieldErrors.password && <FieldError error={fieldErrors.password[0]} />}
      </div>
      <button type="submit">Signup</button>
    </form>
  );
};

export default Signup;
