// src/components/SubscriptionPlans/PricingTable.tsx
import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useAppDispatch, useAppSelector } from '../../store';
import { createCheckoutSession } from '../../store/slices/subscriptionSlice';
import { Check } from 'lucide-react';
import styles from './PricingTable.module.css';

interface SubscriptionPlan {
  id: 'free' | 'pro' | 'enterprise';
  name: string;
  price: number;
  features: string[];
  description: string;
}

export const plans: SubscriptionPlan[] = [
  {
    id: 'free',
    name: 'Free',
    price: 0,
    description: 'For individual authors or small teams just getting started',
    features: [
      'Up to 3 submissions per month',
      'Basic analysis',
      'Simple Editorial Tools',
      'Email support',
    ],
  },
  {
    id: 'pro',
    name: 'Pro',
    price: 50,
    description: 'For growing agencies and publishers',
    features: [
      'All Free features',
      'Up to 50 submissions per month',
      'Advanced analysis',
      'Priority support',
      'Team collaboration tools',
    ],
  },
  {
    id: 'enterprise',
    name: 'Enterprise',
    price: 250,
    description: 'For large publishers with sophisticated needs',
    features: [
      'All Pro features',
      'Unlimited submissions',
      'Custom analytics modules',
      'Dedicated account manager',
    ],
  },
];

export const PricingTable: React.FC = () => {
  const dispatch = useAppDispatch();
  // TODO: Return the organization from the backend and save in Redux store
  // const { organization } = useAppSelector(state => state.userAuth.user?.organizationId);
  const organization = { subscriptionTier: 'free' };
  const { loading } = useAppSelector(state => state.subscription);

  const handleSubscribe = async (planId: SubscriptionPlan['id']) => {
    if (planId === 'free') return;

    try {
      const result = await dispatch(
        createCheckoutSession({
          tier: planId,
          successUrl: `${window.location.origin}/settings/billing/success`,
          cancelUrl: `${window.location.origin}/settings/billing/canceled`,
        })
      ).unwrap();

      const stripe = await loadStripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!
      );
      await stripe?.redirectToCheckout({
        sessionId: result.sessionId,
      });
    } catch (error) {
      console.error('Failed to create checkout session:', error);
    }
  };

  return (
    <div className={styles.pricingContainer}>
      {plans.map(plan => (
        <div key={plan.id} className={styles.pricingCard}>
          <div className={styles.cardHeader}>
            <h3 className={styles.planName}>{plan.name}</h3>
            <p className={styles.planDescription}>{plan.description}</p>
            <div className={styles.priceContainer}>
              <span className={styles.currency}>$</span>
              <span className={styles.price}>{plan.price}</span>
              <span className={styles.period}>/month</span>
            </div>
          </div>

          <div className={styles.cardContent}>
            <ul className={styles.featureList}>
              {plan.features.map(feature => (
                <li key={feature} className={styles.featureItem}>
                  <Check className={styles.checkIcon} size={20} />
                  <span>{feature}</span>
                </li>
              ))}
            </ul>
          </div>

          <div className={styles.cardFooter}>
            <button
              onClick={() => handleSubscribe(plan.id)}
              disabled={loading || organization?.subscriptionTier === plan.id}
              className={styles.subscribeButton}
            >
              {organization?.subscriptionTier === plan.id
                ? 'Current Plan'
                : loading
                  ? 'Processing...'
                  : 'Subscribe'}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};
