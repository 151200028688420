// src/store/rootReducer.ts
import { combineReducers, AnyAction, Reducer } from '@reduxjs/toolkit';
import counterReducer from '../slices/counterSlice';
import organizationReducer from '../slices/organizationSlice';
import userAuthReducer from '../slices/userAuthSlice';
import subscriptionReducer from '../slices/subscriptionSlice';
import userManagementReducer from '../slices/userManagement';
import bugsReducer from '../slices/bugsSlice';
import usageStatisticsReducer from '../slices/usageStatisticsSlice';
import globalActionsReducer, {
  refreshStore,
} from '../slices/globalActionsSlice';

const appReducer = combineReducers({
  counter: counterReducer,
  userAuth: userAuthReducer,
  organization: organizationReducer,
  userManagement: userManagementReducer,
  bugs: bugsReducer,
  usageStatistics: usageStatisticsReducer,
  globalActions: globalActionsReducer,
  subscription: subscriptionReducer,
});

export type RootState = ReturnType<typeof appReducer>;

const rootReducer: Reducer = (
  state: RootState | undefined,
  action: AnyAction
): RootState => {
  if (state && action.type === refreshStore.type) {
    // Reset the state of all reducers except userAuth
    return {
      ...appReducer(undefined, action),
      userAuth: state.userAuth,
    };
  }
  return appReducer(state, action);
};

export default rootReducer;
