import React from 'react';
import styles from './EarlyAccess.module.css';

const EarlyAccessSection: React.FC = () => {
  return (
    <section id="early-access" className={styles.section}>
      <div className={styles.container}>
        <h3 className={styles.sectionTitle}>Join Our Early Access Program</h3>
        <p className={styles.sectionDescription}>
          Be part of the revolution in publishing. We're looking for passionate
          beta testers to help shape the future of Familiar.
        </p>
        <div className={styles.featureGrid}>
          <div className={styles.featureItem}>
            <div className={styles.featureIcon}>🚀</div>
            <h4 className={styles.featureTitle}>Shape the Future</h4>
            <p>
              Your feedback directly influences our product development,
              ensuring Familiar meets real-world needs.
            </p>
          </div>
          <div className={styles.featureItem}>
            <div className={styles.featureIcon}>🔑</div>
            <h4 className={styles.featureTitle}>Exclusive Access</h4>
            <p>
              Be among the first to experience cutting-edge publishing tools
              before they're available to the public.
            </p>
          </div>
          <div className={styles.featureItem}>
            <div className={styles.featureIcon}>🤝</div>
            <h4 className={styles.featureTitle}>Direct Support</h4>
            <p>
              Enjoy personalized support and collaboration with our development
              team throughout the beta phase.
            </p>
          </div>
        </div>
        <div className={styles.ctaContainer}>
          <a href="/signup" className={styles.cta}>
            Sign Up for Early Access
          </a>
        </div>
      </div>
    </section>
  );
};

export default EarlyAccessSection;
