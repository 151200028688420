// src/api/axiosConfig.ts
import axios from 'axios';

export const useMSW = process.env.REACT_APP_USE_MSW === 'true';
const localApiUrl = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: localApiUrl || undefined,
  withCredentials: true,
});
console.log('Using MSW: ' + useMSW);
if (useMSW) {
  // This code is only included in the bundle during development
  if (process.env.NODE_ENV === 'development') {
    const { worker } = require('../mocks/browser');
    console.log('starting MSW');
    worker.start();
  }
}

export default axiosInstance;
