// InfoTooltip.tsx
import React, { useState } from 'react';
import styles from './InfoTooltip.module.css';

type Symbol =
  | 'dagger'
  | 'doubledagger'
  | 'section'
  | 'pilcrow'
  | 'asterisk'
  | 'reference';

interface InfoTooltipProps {
  text: string;
  position?: 'top' | 'right' | 'bottom' | 'left';
  number?: number;
  symbol?: Symbol;
}

const symbolMap: Record<Symbol, string> = {
  dagger: '†', // dagger
  doubledagger: '‡', // double dagger
  section: '§', // section
  pilcrow: '¶', // pilcrow
  asterisk: '*', // asterisk
  reference: '※', // reference mark
};

const InfoTooltip = ({
  text,
  position = 'top',
  number,
  symbol = 'dagger',
}: InfoTooltipProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const getDisplaySymbol = () => {
    if (number !== undefined) return number;
    return symbolMap[symbol];
  };

  return (
    <div className={styles.container}>
      <button
        className={styles.button}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        onFocus={() => setIsVisible(true)}
        onBlur={() => setIsVisible(false)}
        aria-label={`Info: ${text}`}
      >
        <span className={styles.reference}>{getDisplaySymbol()}</span>
      </button>

      {isVisible && (
        <div className={`${styles.tooltip} ${styles[position]}`} role="tooltip">
          <span className={styles.tooltipSymbol}>{getDisplaySymbol()}</span>{' '}
          {text}
          <div className={`${styles.arrow} ${styles[`arrow-${position}`]}`} />
        </div>
      )}
    </div>
  );
};

export default InfoTooltip;
