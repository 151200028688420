import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { post } from '../../api/apiUtils';
import {
  UploadedFileData,
  BookDetails,
  AuthorNGrams,
} from '../../mocks/data/fileData';
import { CollapsibleSection } from '../../components/CollapsibleSection/CollapsibleSection';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpiner';
import styles from './FileDetails.module.css';
import { NGramAnalysisViewer } from '../../components/NGramVisualizer/NGramVisualizer';
import { AlertDialog } from '../../components/AlertDialog/AlertDialog';
import { ScoredSections } from '../../components/ScoredSection/ScoredSections';

export const FileDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [fileDetails, setFileDetails] = useState<UploadedFileData | null>(null);
  const [currentVersionDetails, setCurrentVersionDetails] =
    useState<BookDetails | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isEvaluating, setIsEvaluating] = useState(false);
  const [isAuthorNGramAnalyzing, setIsAuthorNGramAnalyzing] = useState(false);
  // const [isCharacterNGramAnalyzing, setIsCharacterNGramAnalyzing] =
  // useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  // TODO: This is kind of hacky strings based on the titles of the sections and is really brittle.
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>({
    evaluation: false,
    overall: false,
    character: false,
    plot: false,
    promise: false,
    quality: false,
    authorngramanalysis: false,
    characterngramanalysis: false,
  });
  const [isAcceptingOrRejecting, setIsAcceptingOrRejecting] = useState(false);

  const fetchFileDetails = useCallback(async () => {
    if (!id) return;

    setIsLoading(true);
    try {
      const response = await post<UploadedFileData>(`/files/book/${id}`, {});
      setFileDetails(response);
      setCurrentVersionDetails(response.mostRecentDetails);
      setOpenSections({
        overall: !!response.mostRecentDetails?.evaluation,
        authorngramanalysis: !!response.mostRecentDetails?.authorNGramAnalysis,
        characterngramanalysis:
          !!response.mostRecentDetails?.characterNGramAnalysis,
      });
      setError(null);
    } catch (error) {
      console.error('Error fetching file details:', error);
      setError('Failed to load file details. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchFileDetails();
  }, [fetchFileDetails]);

  const fetchVersionDetails = async (versionId: string) => {
    setIsLoading(true);
    try {
      const response = await post<BookDetails>(
        `/files/book/version/${versionId}`,
        {}
      );
      setCurrentVersionDetails(response);
      setError(null);
    } catch (error) {
      console.error('Error fetching version details:', error);
      setError('Failed to load version details. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleVersionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedVersionId = event.target.value;
    fetchVersionDetails(selectedVersionId);
  };

  const handleEvaluationSubmit = async () => {
    if (!id || !currentVersionDetails) return;

    setIsEvaluating(true);
    try {
      await post(`/evaluation`, {
        bookVersionId: currentVersionDetails.versionId,
      });
      await fetchVersionDetails(currentVersionDetails.versionId);
    } catch (error) {
      console.error('Error submitting evaluation:', error);
      setError('Failed to submit evaluation. Please try again later.');
    } finally {
      setIsEvaluating(false);
    }
  };

  const handleAuthorNGramAnalysis = async () => {
    if (!id || !currentVersionDetails) return;

    setIsAuthorNGramAnalyzing(true);
    try {
      await post(`/ngram/author`, {
        bookVersionId: currentVersionDetails.versionId,
      });
      await fetchVersionDetails(currentVersionDetails.versionId);
    } catch (error) {
      console.error('Error submitting author NGram analysis:', error);
      setError(
        'Failed to submit author NGram analysis. Please try again later.'
      );
    } finally {
      setIsAuthorNGramAnalyzing(false);
    }
  };

  // const handleCharacterNGramAnalysis = async () => {
  //   if (!id || !currentVersionDetails) return;

  //   setIsCharacterNGramAnalyzing(true);
  //   try {
  //     await post(`/ngram/character`, {
  //       bookVersionId: currentVersionDetails.versionId,
  //     });
  //     await fetchVersionDetails(currentVersionDetails.versionId);
  //   } catch (error) {
  //     console.error('Error submitting character NGram analysis:', error);
  //     setError(
  //       'Failed to submit character NGram analysis. Please try again later.'
  //     );
  //   } finally {
  //     setIsCharacterNGramAnalyzing(false);
  //   }
  // };

  const handleManuscriptAction = async (
    action: 'bookAccepted' | 'bookRejected'
  ) => {
    if (!id) return;

    setIsAcceptingOrRejecting(true);
    try {
      await post(`/files/book/${id}/action`, {
        action,
      });
      // Optionally refresh the file details after action
      await fetchFileDetails();
    } catch (error) {
      console.error('Error processing manuscript action:', error);
      setError('Failed to process manuscript action. Please try again later.');
    } finally {
      setIsAcceptingOrRejecting(false);
    }
  };

  const toggleSection = (section: string) => {
    console.log(section);
    setOpenSections(prev => ({ ...prev, [section]: !prev[section] }));
  };

  const renderEvaluationSection = (sectionName: string, evaluation: any) => {
    if (!evaluation) return null;
    const lowerCaseName = sectionName.toLowerCase();
    return (
      <CollapsibleSection
        title={sectionName}
        isOpen={openSections[lowerCaseName]}
        onToggle={() => toggleSection(lowerCaseName)}
      >
        {evaluation.verdict !== undefined && (
          <p className={styles.evaluationItem}>
            <strong>Verdict:</strong>{' '}
            {evaluation.verdict
              ? // TODO: Bad copy here for each section. What is each verdict actually saying?
                '👍'
              : '👎'}
          </p>
        )}
        {evaluation.score !== undefined && (
          <p className={styles.evaluationItem}>
            <strong>Score:</strong> {evaluation.score}
          </p>
        )}
        {evaluation.assessment && (
          <p className={styles.evaluationItem}>
            <strong>Assessment:</strong> {evaluation.assessment}
          </p>
        )}
        {evaluation.scoredSections && (
          <ScoredSections sections={evaluation.scoredSections} />
        )}
      </CollapsibleSection>
    );
  };

  const renderNGramAnalysisSection = (
    title: string,
    analysis: AuthorNGrams | string | undefined,
    isPending: boolean,
    handleAnalysis: () => void,
    isAnalyzing: boolean
  ) => {
    const sectionKey = title.toLowerCase().replaceAll(' ', '');
    return (
      <CollapsibleSection
        title={title}
        isOpen={openSections[sectionKey]}
        onToggle={() => toggleSection(sectionKey)}
      >
        <p className={styles.sectionDescription}>
          {title} provides insights into the writing style and patterns used in
          the book, showing the most common word patterns of different lengths.
        </p>
        {isPending ? (
          <p className={styles.pendingAnalysis}>
            Analysis is pending, please check back later.
          </p>
        ) : analysis ? (
          <div className={styles.analysisContainer}>
            {typeof analysis === 'string' ? (
              <pre className={styles.analysisContent}>{analysis}</pre>
            ) : (
              <NGramAnalysisViewer data={analysis} />
            )}
            {!(title === 'Author NGram Analysis') && (
              <button
                className={styles.analysisButton}
                onClick={handleAnalysis}
                disabled={isAnalyzing}
              >
                {isAnalyzing ? 'Analyzing...' : 'Re-run Analysis'}
              </button>
            )}
          </div>
        ) : (
          <div className={styles.noAnalysis}>
            <p>No analysis available.</p>
            <button
              className={styles.analysisButton}
              onClick={handleAnalysis}
              disabled={isAnalyzing}
            >
              {isAnalyzing ? 'Analyzing...' : 'Start Analysis'}
            </button>
          </div>
        )}
      </CollapsibleSection>
    );
  };

  const handleDeleteBook = async () => {
    if (!id) return;

    setIsDeleting(true);
    try {
      await post(`/files/book/${id}/delete`, {});
      // Redirect to submissions after a brief delay to show success message
      setTimeout(() => {
        navigate('/submissions');
      }, 2000);
    } catch (error) {
      console.error('Error deleting book:', error);
      setError('Failed to delete book. Please try again later.');
    } finally {
      setIsDeleting(false);
    }
  };

  const statusToHuman = (
    status: 'bookAccepted' | 'bookRejected' | 'bookPending'
  ) => {
    switch (status) {
      case 'bookAccepted':
        return 'Accepted';
      case 'bookRejected':
        return 'Rejected';
      case 'bookPending':
        return 'Pending';
      default:
        return 'Unknown';
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!fileDetails || !currentVersionDetails) {
    return (
      <div className={styles.errorMessage}>No file details available.</div>
    );
  }

  return (
    <div className={styles.container}>
      <Link to="/submissions" className={styles.backLink}>
        &larr; Back to Submissions
      </Link>
      <h1 className={styles.title}>{fileDetails.title}</h1>
      {error && <p className={styles.errorMessage}>{error}</p>}
      <div className={styles.card}>
        <h2 className={styles.sectionTitle}>Book Details</h2>
        {fileDetails.versions.length > 1 && (
          <div className={styles.versionSelector}>
            <label htmlFor="versionSelect">Version: </label>
            <select
              id="versionSelect"
              value={currentVersionDetails.versionId}
              onChange={handleVersionChange}
            >
              {fileDetails.versions.map(([versionNumber, versionId]) => (
                <option key={versionId} value={versionId}>
                  Version {versionNumber}
                </option>
              ))}
            </select>
          </div>
        )}
        <ul className={styles.detailsList}>
          {currentVersionDetails.currentStatus &&
            currentVersionDetails.currentStatus !== 'bookPending' && (
              <li className={styles.detailsItem}>
                <strong>Status:</strong>{' '}
                {statusToHuman(currentVersionDetails.currentStatus)}
              </li>
            )}
          <li className={styles.detailsItem}>
            <strong>Upload Date:</strong>{' '}
            {new Date(fileDetails.uploadDate).toLocaleDateString()}
          </li>
          {currentVersionDetails.author && (
            <li className={styles.detailsItem}>
              <strong>Author:</strong> {currentVersionDetails.author}
            </li>
          )}
          {currentVersionDetails.wordCount && (
            <li className={styles.detailsItem}>
              <strong>Word Count:</strong>{' '}
              {currentVersionDetails.wordCount.toLocaleString()}
            </li>
          )}
          {currentVersionDetails.chapterCount && (
            <li className={styles.detailsItem}>
              <strong>Chapter Count:</strong>{' '}
              {currentVersionDetails.chapterCount}
            </li>
          )}
          {currentVersionDetails.firstLine && (
            <li className={styles.detailsItem}>
              <strong>Opening:</strong> {currentVersionDetails.firstLine}
            </li>
          )}
          <div className={styles.actionButtons}>
            <button
              onClick={() => handleManuscriptAction('bookAccepted')}
              disabled={isAcceptingOrRejecting}
              className={`${styles.actionButton} ${styles.acceptButton}`}
            >
              {isAcceptingOrRejecting ? 'Processing...' : 'Accept'}
            </button>
            <button
              onClick={() => handleManuscriptAction('bookRejected')}
              disabled={isAcceptingOrRejecting}
              className={`${styles.actionButton} ${styles.rejectButton}`}
            >
              {isAcceptingOrRejecting ? 'Processing...' : 'Reject'}
            </button>
          </div>
        </ul>

        <h2 className={styles.sectionTitle}>Analysis</h2>
        <div className={styles.analysisSections}>
          {renderNGramAnalysisSection(
            'Author NGram Analysis',
            currentVersionDetails.authorNGramAnalysis,
            currentVersionDetails.authorNGramAnalysisPending,
            handleAuthorNGramAnalysis,
            isAuthorNGramAnalyzing
          )}

          <CollapsibleSection
            title="Evaluation"
            isOpen={openSections.evaluation}
            onToggle={() => toggleSection('evaluation')}
          >
            <p className={styles.sectionDescription}>
              Evaluations over a number of different dimensions of the story.
            </p>
            {currentVersionDetails.evaluation ? (
              <div className={styles.evaluationSections}>
                {renderEvaluationSection(
                  'Overall',
                  currentVersionDetails.evaluation.overall
                )}
                {renderEvaluationSection(
                  'Character',
                  currentVersionDetails.evaluation.character
                )}
                {renderEvaluationSection(
                  'Plot',
                  currentVersionDetails.evaluation.plot
                )}
                {renderEvaluationSection(
                  'Promise',
                  currentVersionDetails.evaluation.promise
                )}
                {renderEvaluationSection(
                  'Quality',
                  currentVersionDetails.evaluation.quality
                )}
              </div>
            ) : currentVersionDetails.evaluationPending ? (
              <p className={styles.pendingEvaluation}>
                Evaluation results are pending, check back soon.
              </p>
            ) : (
              <div className={styles.noEvaluation}>
                <p>No evaluation available.</p>
                <button
                  className={styles.evaluationButton}
                  onClick={handleEvaluationSubmit}
                  disabled={isEvaluating}
                >
                  {isEvaluating ? 'Evaluating...' : 'Start Evaluation'}
                </button>
              </div>
            )}
          </CollapsibleSection>
          <p>Character Analysis Coming Soon</p>
          {/* COMING SOON
          {renderNGramAnalysisSection(
            'Character NGram Analysis',
            currentVersionDetails.characterNGramAnalysis,
            currentVersionDetails.characterNGramAnalysisPending,
            handleCharacterNGramAnalysis,
            isCharacterNGramAnalyzing
          )} */}
        </div>
        <div className={styles.deleteSection}>
          <h2 className={styles.sectionTitle}>Danger Zone</h2>
          <p className={styles.deleteWarning}>
            Deleting this book will permanently remove it and all its versions.
            This action cannot be undone.
          </p>

          <button
            className={styles.deleteButton}
            onClick={() => setShowDeleteDialog(true)}
            disabled={isDeleting}
          >
            {isDeleting ? 'Deleting...' : 'Delete Book'}
          </button>

          <AlertDialog
            isOpen={showDeleteDialog}
            title="Are you absolutely sure?"
            description={`This will permanently delete "${fileDetails.title}" and all its associated data. This action cannot be undone.`}
            onConfirm={handleDeleteBook}
            onCancel={() => setShowDeleteDialog(false)}
            confirmText="Yes, Delete Book"
            cancelText="Cancel"
            isConfirming={isDeleting}
          />
        </div>
      </div>
    </div>
  );
};
