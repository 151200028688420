import React from 'react';
import { useDispatch } from 'react-redux';
import { post } from '../../api/apiUtils';
import styles from './SupportBanner.module.css';
import { refreshStore } from '../../store/slices/globalActionsSlice';
import { useNavigate } from 'react-router-dom';

const SupportBanner: React.FC = () => {
  const dispatch = useDispatch();
  const supportSession = JSON.parse(
    localStorage.getItem('supportSession') || '{}'
  );
  const navigate = useNavigate();

  const handleEndSupport = async () => {
    try {
      await post('/admin/provide-support/end-session', {
        userId: supportSession.userId,
      });
      localStorage.removeItem('supportSession');
      dispatch(refreshStore());
      navigate('/admin');
    } catch (error) {
      console.error('Error ending support session:', error);
      // Even if we error out, go ahead and remove the support session token.
      localStorage.removeItem('supportSession');
    }
  };

  if (!supportSession.userEmail) return null;

  return (
    <div className={styles.supportBanner}>
      <p>Providing support to user: {supportSession.userEmail}</p>
      <button onClick={handleEndSupport}>End Support</button>
    </div>
  );
};

export default SupportBanner;
