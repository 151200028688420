// src/components/FileUploader/index.tsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { post } from '../../api/apiUtils';
import { useSelector } from 'react-redux';
import styles from './FileUploader.module.css';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { RootState } from '../../store/reducers/rootReducer';

export interface UploadedFile {
  id: string;
  title: string;
  overallScore?: number;
  lastUploadDate: string;
  versions: [number, string][];
  status: 'bookAccepted' | 'bookRejected' | 'bookPending';
}

interface FileUploaderProps {
  onFileUpload: (files: UploadedFile[]) => void;
  onUploadError: (error: string) => void;
  existingFiles: UploadedFile[];
}

export const FileUploader: React.FC<FileUploaderProps> = ({
  onFileUpload,
  onUploadError,
  existingFiles,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [title, setTitle] = useState('');
  const [selectedExistingFile, setSelectedExistingFile] = useState<
    string | null
  >(null);
  const [userEditedTitle, setUserEditedTitle] = useState(false);
  const user = useSelector((state: RootState) => state.userAuth.user);
  const orgId = user?.organizationId!;
  const [failed, setFailed] = useState(false);

  useEffect(() => {
    if (selectedFile && !userEditedTitle) {
      setTitle(selectedFile.name.split('.').slice(0, -1).join('.'));
    }
  }, [selectedFile, userEditedTitle]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.name.endsWith('.docx') || file.name.endsWith('.txt')) {
        setSelectedFile(file);
        setUserEditedTitle(false);
      } else {
        onUploadError('Please select a .docx or .txt file.');
        event.target.value = ''; // Reset the input
      }
    }
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
    setUserEditedTitle(true);
  };

  const handleExistingFileChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedExistingFile(event.target.value);
    if (event.target.value !== 'new') {
      const existingFile = existingFiles.find(
        file => file.id === event.target.value
      );
      if (existingFile && !userEditedTitle) {
        setTitle(existingFile.title);
      }
    }
  };

  const getFileExtension = (filename: string) => {
    const lastDotIndex = filename.lastIndexOf('.');
    return lastDotIndex === -1 ? '' : filename.slice(lastDotIndex);
  };

  const handleUpload = async () => {
    if (!selectedFile || !title) return;

    const extension = getFileExtension(selectedFile.name);

    setUploading(true);

    try {
      const { fileUrl, fileId } = await post<{
        fileUrl: string;
        fileId: string;
      }>(`/files/${orgId}/get-presigned-url`, {
        fileName: selectedFile.name,
        title: title,
        extension: extension,
        existingBookId: selectedExistingFile,
      });

      await axios.put(fileUrl, selectedFile, {
        headers: { 'Content-Type': selectedFile.type },
      });

      const uploadedFileResponse = await post<{ books: UploadedFile[] }>(
        `/files/${fileId}/confirm-upload`,
        {}
      );

      onFileUpload(uploadedFileResponse.books);
      setSelectedFile(null);
      setTitle('');
      setSelectedExistingFile(null);
      setUserEditedTitle(false);
    } catch (error) {
      console.error('Error uploading file:', error);
      setFailed(true);
      onUploadError('File upload failed. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className={styles.uploaderContainer}>
      <p className={styles.instructions}>
        Please select a .docx or .txt file to upload. Max file size: 10MB
      </p>
      <input
        type="file"
        accept=".docx,.txt"
        onChange={handleFileChange}
        disabled={uploading}
        className={styles.fileInput}
        id="fileInput"
      />
      <label htmlFor="fileInput" className={styles.fileInputLabel}>
        {uploading ? 'Uploading...' : 'Choose File'}
      </label>
      {selectedFile && (
        <p className={styles.selectedFileName}>Selected: {selectedFile.name}</p>
      )}
      <div className={styles.inputGroup}>
        <label htmlFor="titleInput" className={styles.inputLabel}>
          Title:
        </label>
        <input
          id="titleInput"
          type="text"
          value={title}
          onChange={handleTitleChange}
          placeholder="Enter document title"
          className={styles.titleInput}
          disabled={uploading}
        />
        <p className={styles.inputDescription}>
          Provide a title for your document. This will be used for display
          purposes.
        </p>
      </div>
      <div className={styles.inputGroup}>
        <label htmlFor="versionSelect" className={styles.inputLabel}>
          Version:
        </label>
        <select
          id="versionSelect"
          value={selectedExistingFile || 'new'}
          onChange={handleExistingFileChange}
          className={styles.selectExisting}
          disabled={uploading}
        >
          <option value="new">New Upload</option>
          {existingFiles.map(file => (
            <option key={file.id} value={file.id}>
              {file.title}
            </option>
          ))}
        </select>
        <p className={styles.inputDescription}>
          Select 'New Upload' for a new document or choose an existing document
          to upload a new version.
        </p>
      </div>
      <button
        onClick={handleUpload}
        disabled={!selectedFile || !title || uploading}
        className={styles.uploadButton}
      >
        {uploading ? 'Uploading...' : 'Upload File'}
      </button>
      {failed && !uploading && (
        <span className={styles.errorBox}>
          <ErrorMessage message={'File upload failed'} />
        </span>
      )}
    </div>
  );
};
