import React from 'react';
import styles from './LandingPage.module.css';
import EarlyAccessSection from '../EarlyAccess/EarlyAccess';
import { plans } from '../PricingTable/PricingTable';
import { Check } from 'lucide-react';

const LandingPage = () => {
  return (
    <div>
      {/* Hero Section */}
      <section className={styles.heroSection}>
        <div className={styles.container}>
          <h2 className={styles.heroTitle}>
            Find the hidden gems in your inbox.
          </h2>
          <p className={styles.heroSubtitle}>
            Prioritize the best manuscripts.
          </p>
          <a href="/signup" className={styles.button}>
            Get Early Access&#8594;
          </a>
        </div>
      </section>

      {/* Product Overview */}
      <section id="product" className={styles.section}>
        <div className={styles.container}>
          <h3 className={styles.sectionTitle}>What We Offer</h3>
          <div className={styles.featureGrid}>
            <div className={styles.featureItem}>
              <div className={styles.featureIcon}>🔍</div>
              <h4 className={styles.featureTitle}>Smart Filtering</h4>
              <p>
                Automatically categorize and prioritize submissions based on
                your preferences.
              </p>
            </div>
            <div className={styles.featureItem}>
              <div className={styles.featureIcon}>📚</div>
              <h4 className={styles.featureTitle}>Manuscript Analysis</h4>
              <p>
                Content analysis that pulls the best manuscripts to the top of
                your list.
              </p>
            </div>
            <div className={styles.featureItem}>
              <div className={styles.featureIcon}>✏️</div>
              <h4 className={styles.featureTitle}>Editorial Tools</h4>
              <p>
                Industry leading editorial tools that provide unique insights
                into every story instantly.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="landing-page">
        <EarlyAccessSection />
      </div>

      {/* Pricing Section */}
      <section
        id="pricing"
        className={`${styles.section} ${styles.pricingSection}`}
      >
        <div className={styles.container}>
          <h3 className={styles.sectionTitle}>Pricing Plans</h3>
          <div className={styles.pricingGrid}>
            <div className={styles.pricingCard}>
              <h4 className={styles.pricingTitle}>Free</h4>
              <p className={styles.pricingPrice}>
                ${plans[0].price}
                <span>/month</span>
              </p>
              <ul className={styles.pricingFeatures}>
                {plans[0].features.map((feature, index) => (
                  <li key={feature}>
                    <Check size={20} />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div className={`${styles.pricingCard} ${styles.featured}`}>
              <h4 className={styles.pricingTitle}>Pro</h4>
              <p className={styles.pricingPrice}>
                ${plans[1].price}
                <span>/month</span>
              </p>
              <ul className={styles.pricingFeatures}>
                {plans[1].features.map((feature, index) => (
                  <li key={feature}>
                    <Check size={20} />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.pricingCard}>
              <h4 className={styles.pricingTitle}>Enterprise</h4>
              <p className={styles.pricingPrice}>
                ${plans[2].price}
                <span>/month</span>
              </p>
              <ul className={styles.pricingFeatures}>
                {plans[2].features.map((feature, index) => (
                  <li key={feature}>
                    <Check size={20} />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.section}>
        <div className={styles.featureItem}>
          <p className={styles.heroSubtitle}>
            Unlock your publishing potential.
          </p>
          <a href="/signup" className={styles.button}>
            Get Started Now
          </a>
        </div>
      </section>
      {/* Testimonials Section */}
      {/* Hidden until we get early feedback */}
      {/* <section id="testimonials" className={styles.section}>
        <div className={styles.container}>
          <h3 className={styles.sectionTitle}>What Our Users Say</h3>
          <div className={styles.testimonialGrid}>
            <div className={styles.testimonialCard}>
              <p className={styles.testimonialText}>"Familiar has revolutionized our manuscript review process. We're discovering amazing new authors faster than ever!"</p>
              <p className={styles.testimonialAuthor}>- Jane Doe, Senior Editor at BookHouse Publishing</p>
            </div>
            <div className={styles.testimonialCard}>
              <p className={styles.testimonialText}>"The AI analysis is spot-on. It's like having a team of experienced readers working around the clock."</p>
              <p className={styles.testimonialAuthor}>- John Smith, Literary Agent</p>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default LandingPage;
