import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.leftContent}>
          <span className={styles.copyright}>© 2024 Familiar.</span>
          <img
            src="/favicon-16x16.png"
            alt="Familiar logo"
            className={styles.logo}
          />
        </div>
        <div className={styles.rightContent}>
          <span className={styles.rights}>All rights reserved.</span>
          <Link to="/privacy-policy" className={styles.privacyLink}>
            Privacy Policy
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
