import React, { useState, useMemo } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import styles from './NGramVisualizer.module.css';
import { AuthorNGrams } from '../../mocks/data/fileData';

interface NGramVisualizerProps {
  ngramType: keyof AuthorNGrams;
  data: string;
  maxDisplay?: number;
}

export const NGramVisualizer: React.FC<NGramVisualizerProps> = ({
  ngramType,
  data,
  maxDisplay = 10,
}) => {
  const [showCount, setShowCount] = useState<number>(10);

  const parsedData = useMemo(() => {
    const parsed = JSON.parse(data);
    return Object.entries(parsed)
      .map(([term, count]) => ({ term, count: Number(count) }))
      .sort((a, b) => b.count - a.count)
      .slice(0, showCount);
  }, [data, showCount]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h4 className={styles.title}>{ngramType}</h4>
        <select
          value={showCount}
          onChange={e => setShowCount(Number(e.target.value))}
          className={styles.select}
        >
          <option value={5}>Top 5</option>
          <option value={10}>Top 10</option>
          <option value={15}>Top 15</option>
          <option value={20}>Top 20</option>
        </select>
      </div>

      <div className={styles.chartContainer}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={parsedData} layout="vertical" margin={{ left: 100 }}>
            <XAxis type="number" />
            <YAxis
              type="category"
              dataKey="term"
              width={100}
              tickFormatter={(value: string) =>
                value.length > 15 ? `${value.substring(0, 15)}...` : value
              }
            />
            <Tooltip />
            <Bar dataKey="count" fill="#4CAF50" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.tableHeader}>Term</th>
              <th className={`${styles.tableHeader} ${styles.rightAlign}`}>
                Count
              </th>
            </tr>
          </thead>
          <tbody>
            {parsedData.map((item, index) => (
              <tr key={index} className={styles.tableRow}>
                <td className={styles.tableCell}>{item.term}</td>
                <td className={`${styles.tableCell} ${styles.rightAlign}`}>
                  {item.count}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

interface NGramAnalysisViewerProps {
  data: AuthorNGrams;
}

export const NGramAnalysisViewer: React.FC<NGramAnalysisViewerProps> = ({
  data,
}) => {
  const [selectedNGram, setSelectedNGram] =
    useState<keyof AuthorNGrams>('unigrams');

  return (
    <div className={styles.analysisContainer}>
      <div className={styles.controls}>
        <label htmlFor="ngram-select" className={styles.label}>
          Select N-gram type:
        </label>
        <select
          id="ngram-select"
          value={selectedNGram}
          onChange={e => setSelectedNGram(e.target.value as keyof AuthorNGrams)}
          className={styles.select}
        >
          <option value="unigrams">Unigrams</option>
          <option value="bigrams">Bigrams</option>
          <option value="trigrams">Trigrams</option>
          <option value="quadgrams">Quadgrams</option>
          <option value="quintgrams">Quintgrams</option>
          <option value="sextgrams">Sextgrams</option>
        </select>
      </div>

      <NGramVisualizer ngramType={selectedNGram} data={data[selectedNGram]} />
    </div>
  );
};
