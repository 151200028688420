// src/api/apiUtils.ts
import axiosInstance from './axiosConfig';
import { handleApiError } from './errorHandling';
import { logout } from '../store/slices/userAuthSlice';
import { AxiosError, AxiosRequestConfig } from 'axios';
import store from '../store';

// Extend AxiosRequestConfig to include our custom flag
export interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  skipAuthInterceptor?: boolean;
}

axiosInstance.interceptors.response.use(
  response => response,
  (error: AxiosError) => {
    // TODO: Also check the error message for "You are logged out." Text
    if (error.response?.status === 401) {
      const config = error.config as CustomAxiosRequestConfig;
      // Check if we should skip the auth interceptor for this request
      if (
        config &&
        !config.skipAuthInterceptor &&
        error.response?.status === 401
      ) {
        // If we receive a 401 Unauthorized, dispatch logout action
        store.dispatch(logout());
      }
    }
    return Promise.reject(error);
  }
);

export const logoutApi = async (): Promise<void> => {
  try {
    await axiosInstance.post('/logout', {}, {
      skipAuthInterceptor: true,
    } as CustomAxiosRequestConfig);
    // The server should handle clearing the cookie
  } catch (error) {
    console.error('Logout failed:', error);
    throw error;
  }
};

export const get = async <T>(url: string, params?: object): Promise<T> => {
  try {
    const response = await axiosInstance.get<T>(url, { params });
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const post = async <T>(url: string, data: object): Promise<T> => {
  try {
    const response = await axiosInstance.post<T>(url, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const put = async <T>(url: string, data: object): Promise<T> => {
  try {
    const response = await axiosInstance.put<T>(url, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const del = async <T>(url: string): Promise<T> => {
  try {
    const response = await axiosInstance.delete<T>(url);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
