// src/pages/Bugs.tsx
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import { BugDetails, fetchBugs } from '../../store/slices/bugsSlice';
import AdminDashboardLayout from '../../components/AdminDashboardLayout/AdminDashboardLayout';
import styles from './Bugs.module.css';
import { RootState } from '../../store/reducers/rootReducer';

const Bugs: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { bugs, countLast24Hours, loading, error } = useSelector(
    (state: RootState) => state.bugs
  );

  useEffect(() => {
    dispatch(fetchBugs());
  }, [dispatch]);

  // Function to format the date string
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleString(); // This will use the user's locale settings for formatting
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <AdminDashboardLayout>
      <div className={styles.bugTrackingContainer}>
        <h1>Bug Tracking</h1>
        <div className={styles.bugSummary}>
          <h2>Bug Summary</h2>
          <p>
            Bugs in the last 24 hours: <strong>{countLast24Hours}</strong>
          </p>
        </div>
        <div className={styles.bugListContainer}>
          <h2>Recent Bugs</h2>
          <div className={styles.bugList}>
            {bugs.map((bug: BugDetails) => (
              <div key={bug.id} className={styles.bugItem}>
                <h3>Bug ID: {bug.id}</h3>
                <p>
                  <strong>Error:</strong> {bug.error}
                </p>
                <p>
                  <strong>Timestamp:</strong> {formatDate(bug.createdAt)}
                </p>
                <details>
                  <summary>View Callstack</summary>
                  <pre>{bug.callstack}</pre>
                </details>
              </div>
            ))}
          </div>
        </div>
      </div>
    </AdminDashboardLayout>
  );
};

export default Bugs;
