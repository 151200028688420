import { AxiosError } from 'axios';

export interface FieldErrors {
  [field: string]: string[];
}

export class ApiError extends Error {
  status: number;
  fieldErrors?: FieldErrors;

  constructor(message: string, status: number, fieldErrors?: FieldErrors) {
    super(message);
    this.name = 'ApiError';
    this.status = status;
    this.fieldErrors = fieldErrors;
  }
}

export const handleApiError = (error: unknown): ApiError => {
  if (error instanceof AxiosError) {
    if (error.response) {
      const responseData = error.response.data as Record<string, unknown>;
      const fieldErrors = responseData.errors as FieldErrors;

      let errorMessage = 'An error occurred';
      if (fieldErrors) {
        const firstErrorField = Object.keys(fieldErrors)[0];
        errorMessage = fieldErrors[firstErrorField][0] || errorMessage;
      }

      return new ApiError(errorMessage, error.response.status, fieldErrors);
    } else if (error.request) {
      return new ApiError('No response received from server', 0);
    } else {
      return new ApiError(error.message, 0);
    }
  } else {
    return new ApiError('An unexpected error occurred', 0);
  }
};
