import React, { useState, useEffect } from 'react';
import styles from './PrivacyPolicy.module.css';

const PrivacyPolicy: React.FC = () => {
  const [iframeHeight, setIframeHeight] = useState('100vh');

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data && event.data.type === 'iframe-height') {
        setIframeHeight(`${event.data.height}px`);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <div className={styles.privacyPolicy}>
      <iframe
        src="/privacy-policy.html"
        title="Privacy Policy"
        width="100%"
        height={iframeHeight}
        frameBorder="0"
        scrolling="no"
      />
    </div>
  );
};

export default PrivacyPolicy;
