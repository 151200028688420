import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { get, logoutApi } from '../../api/apiUtils';
import { LoginResponse } from '../../pages/Login/Login';
import { User } from '../../mocks/handlers';
import axios from 'axios';

interface UserAuthState {
  isAuthenticated: boolean;
  user: User | null;
  loading: boolean;
  error: string | null;
}

const initialState: UserAuthState = {
  isAuthenticated: false,
  user: null,
  loading: false,
  error: null,
};

export const checkSession = createAsyncThunk(
  'userAuth/checkSession',
  async (_, { rejectWithValue }) => {
    try {
      const response = await get<LoginResponse>('/get-initial-data', {});
      return response.userDetails;
    } catch (error) {
      // TODO: Right now, when you hit the landing page logged-out this fires, fails, and then hits the axios logout interceptor
      // so there are two 401 API calls back to back on every fresh page visit. Maybe we can run this only if the user has some kind of permanent cookie that means they've logged in before?
      if (axios.isAxiosError(error)) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 405) {
            return rejectWithValue(
              'Method not allowed. Please check API endpoint configuration.'
            );
          } else if (error.response.status === 401) {
            return rejectWithValue('Unauthorized. User is not logged in.');
          }
        } else if (error.request) {
          // The request was made but no response was received
          return rejectWithValue('No response received from server.');
        }
      }
      // Something happened in setting up the request that triggered an Error
      return rejectWithValue('An unexpected error occurred.');
    }
  }
);

export const logout = createAsyncThunk(
  'userAuth/logout',
  async (_, { rejectWithValue }) => {
    try {
      await logoutApi();
      return undefined;
    } catch (error) {
      return rejectWithValue('Failed to logout');
    }
  }
);

const userAuthSlice = createSlice({
  name: 'userAuth',
  initialState,
  reducers: {
    loginStart: state => {
      state.loading = true;
      state.error = null;
    },
    loginSuccess: (state, action: PayloadAction<User>) => {
      state.isAuthenticated = true;
      state.user = action.payload;
      state.loading = false;
      state.error = null;
    },
    loginFailure: (state, action: PayloadAction<string>) => {
      state.isAuthenticated = false;
      state.user = null;
      state.loading = false;
      state.error = action.payload;
    },
    logout: state => {
      state.isAuthenticated = false;
      state.user = null;
      state.loading = false;
      state.error = null;
    },
    updateProfile: (state, action: PayloadAction<Partial<User>>) => {
      if (state.user) {
        state.user = { ...state.user, ...action.payload };
      }
    },
    changePassword: () => {
      console.log('Password changed successfully');
    },
    deleteAccount: state => {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(checkSession.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(checkSession.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.user = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(checkSession.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.user = null;
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(logout.pending, state => {
        state.loading = true;
      })
      .addCase(logout.fulfilled, state => {
        state.isAuthenticated = false;
        state.user = null;
        state.loading = false;
        state.error = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  updateProfile,
  changePassword,
  deleteAccount,
} = userAuthSlice.actions;

export default userAuthSlice.reducer;
