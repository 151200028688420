import React from 'react';
import { Link } from 'react-router-dom';
import styles from './About.module.css';

const About: React.FC = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>About Familiar</h1>
      <h2 className={styles.subtitle}>Empowering the Future of Storytelling</h2>
      <p>
        Familiar is not just another set of tools – it's a revolution in the
        world of publishing. Born from the minds of software engineers who
        transitioned into <a href="https:infernalmoose.com">publishing</a>,
        Familiar is the suite of tools we wished we had when we first embarked
        on our publishing journey.
      </p>

      <section className={styles.section}>
        <h3 className={styles.sectionTitle}>Our Mission</h3>
        <p>
          At Familiar, we're on a mission to build a comprehensive,
          best-in-class toolkit for authors, editors, publishers, and readers.
          We believe that world-changing stories deserve world-class tools, and
          we're here to make that a reality.
        </p>
      </section>

      <section className={styles.section}>
        <h3 className={styles.sectionTitle}>Why Familiar?</h3>
        <ol className={styles.list}>
          <li>
            <strong>Built by Publishers, for Publishers</strong>: Our team
            intimately understands the challenges faced by modern storytellers
            and publishers. We're building all of the things we need to publish
            better books ourselves.
          </li>
          <li>
            <strong>Verticalized Solution</strong>: Unlike piecemeal approaches,
            Familiar is building a cohesive suite of tools designed to work
            seamlessly together; streamlining your workflow from initial draft
            to final publication and beyond.
          </li>
          <li>
            <strong>Cutting-Edge Technology</strong>: Leveraging our background
            in software engineering, we're bringing the latest in tech
            innovation to the publishing world.
          </li>
          <li>
            <strong>Adaptive and Evolving</strong>: The publishing landscape is
            constantly changing, and so are we. Familiar is committed to
            continuous improvement and adaptation to meet the ever-evolving
            needs of the industry. Let us know what problems you've been facing
            lately and we're happy to build solutions to help!
          </li>
        </ol>
      </section>

      <section className={styles.section}>
        <h3 className={styles.sectionTitle}>Our Vision</h3>
        <p>
          We envision a future where the technical aspects of publishing are no
          longer a barrier to great storytelling. Where authors can focus on
          their craft, editors can streamline their processes, publishers can
          make data-driven decisions, and readers can discover and engage with
          content in new and exciting ways.
        </p>
      </section>

      <section className={styles.section}>
        <h3 className={styles.sectionTitle}>Join the Revolution</h3>
        <p>
          Familiar is more than just a tool – it's a movement. We're looking for
          forward-thinking authors, editors, and publishers who are ready to
          embrace the future of storytelling. By becoming an early user of
          Familiar, you're not just adopting a new tool; you're joining a
          community of innovators who are shaping the future of publishing.
          We'll be relying on you to help test and provide feedback on the
          things that help you hone your craft the best.
        </p>
        <p>
          Are you ready to familiarize yourself with the future of publishing?
          Join us on this exciting journey, and let's create something
          extraordinary together.
        </p>
      </section>

      <div className={styles.ctaContainer}>
        <Link to="/signup" className={styles.cta}>
          Get Early Access
        </Link>
      </div>

      <footer className={styles.footer}>
        <img src="/android-chrome-192x192.png" alt="Familiar logo" />
        <div>
          <em>Familiar Team</em>
        </div>
      </footer>
    </div>
  );
};

export default About;
