// src/store/slices/subscriptionSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { post } from '../../api/apiUtils';
import { SubscriptionTier } from '../../types/subscriptions';

export const createCheckoutSession = createAsyncThunk(
  'subscription/createCheckoutSession',
  async (data: {
    tier: SubscriptionTier;
    successUrl: string;
    cancelUrl: string;
  }) => {
    const response = await post<{ sessionId: string }>(
      '/create-checkout-session',
      data
    );
    return response;
  }
);

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: {
    loading: false,
    error: null as string | null,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(createCheckoutSession.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCheckoutSession.fulfilled, state => {
        state.loading = false;
      })
      .addCase(createCheckoutSession.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message ?? 'Failed to create checkout session';
      });
  },
});

export default subscriptionSlice.reducer;
