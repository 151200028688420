// src/store/slices/userManagementSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { post } from '../../api/apiUtils';

interface User {
  id: number;
  name: string;
  email: string;
}

interface UserManagementState {
  users: User[];
  totalUsers: number;
  loading: boolean;
  error: string | null;
}

const initialState: UserManagementState = {
  users: [],
  totalUsers: 0,
  loading: false,
  error: null,
};

export const fetchUsers = createAsyncThunk(
  'userManagement/fetchUsers',
  async ({ page, limit }: { page: number; limit: number }) => {
    const response = await post<{ users: User[]; total: number }>(
      `/admin/users`,
      { page, limit }
    );
    return response;
  }
);

const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchUsers.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchUsers.fulfilled,
        (state, action: PayloadAction<{ users: User[]; total: number }>) => {
          state.loading = false;
          state.users = action.payload.users;
          state.totalUsers = action.payload.total;
        }
      )
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch users';
      });
  },
});

export default userManagementSlice.reducer;
