import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OrganizationState {
  id: string | null;
  name: string | null;
  invitedUsers: string[];
  pendingInvites: { id: string; organizationName: string }[];
}

const initialState: OrganizationState = {
  id: null,
  name: null,
  invitedUsers: [],
  pendingInvites: [],
};

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganization: (
      state,
      action: PayloadAction<{ id: string; name: string }>
    ) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
    },
    inviteUser: (state, action: PayloadAction<string>) => {
      state.invitedUsers.push(action.payload);
    },
    setPendingInvites: (
      state,
      action: PayloadAction<{ id: string; organizationName: string }[]>
    ) => {
      state.pendingInvites = action.payload;
    },
    acceptInvitation: (state, action: PayloadAction<string>) => {
      state.pendingInvites = state.pendingInvites.filter(
        invite => invite.id !== action.payload
      );
    },
    rejectInvitation: (state, action: PayloadAction<string>) => {
      state.pendingInvites = state.pendingInvites.filter(
        invite => invite.id !== action.payload
      );
    },
  },
});

export const {
  setOrganization,
  inviteUser,
  setPendingInvites,
  acceptInvitation,
  rejectInvitation,
} = organizationSlice.actions;

export default organizationSlice.reducer;
