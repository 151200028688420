// src/mocks/data/fileData.js

export interface ScoredSection {
  sectionName: string;
  sectionScore: number;
  sectionText: string;
}

export interface BookEvalOverall {
  assessment: string;
  score: number;
  verdict: boolean;
}

export interface BookEvalCharacter {
  verdict: boolean;
  scoredSections: ScoredSection[];
}

export interface BookEvalPlot {
  verdict: boolean;
  scoredSections: ScoredSection[];
}

export interface BookEvalPromise {
  verdict: boolean;
  scoredSections: ScoredSection[];
}

export interface BookEvalQuality {
  verdict: boolean;
  scoredSections: ScoredSection[];
}
export interface BookEvaluation {
  overall: BookEvalOverall;
  character?: BookEvalCharacter;
  plot?: BookEvalPlot;
  promise?: BookEvalPromise;
  quality?: BookEvalQuality;
}

export interface AuthorNGrams {
  // strings from the BE that are actually objects? bad typing probably somewhere
  unigrams: string;
  bigrams: string;
  trigrams: string;
  quadgrams: string;
  quintgrams: string;
  sextgrams: string;
}

export interface BookDetails {
  author?: string;
  wordCount?: number;
  chapterCount?: number;
  firstLine?: string;
  evaluation?: BookEvaluation;
  evaluationPending: boolean;
  authorNGramAnalysis?: AuthorNGrams;
  authorNGramAnalysisPending: boolean;
  characterNGramAnalysis?: string;
  characterNGramAnalysisPending: boolean;
  versionNumber: number;
  versionId: string;
  currentStatus: 'bookAccepted' | 'bookRejected' | 'bookPending';
}
export interface UploadedFileData {
  id: string;
  title: string;
  uploadDate: string;
  versions: [number, string][];
  mostRecentDetails: BookDetails;
}

const mockAuthorNGrams: AuthorNGrams = {
  unigrams: '{}',
  bigrams: '{}',
  trigrams: '{}',
  quadgrams: '{}',
  quintgrams: '{}',
  sextgrams: '{}',
};

export const mockFiles: UploadedFileData[] = [
  {
    id: '1',
    title: 'The Great Gatsby',
    uploadDate: '2023-09-15T10:30:00Z',
    versions: [[1, '1']],
    mostRecentDetails: {
      author: 'F. Scott Fitzgerald',
      wordCount: 47094,
      chapterCount: 9,
      evaluation: {
        overall: {
          assessment: '',
          score: 50,
          verdict: false,
        },
        // scores: {
        //   plotCoherence: 8.5,
        //   characterDevelopment: 9.0,
        //   proseQuality: 9.5,
        //   thematicDepth: 9.0,
        // },
        // descriptions: {
        //   plotCoherence: "The narrative is tightly woven, with events building logically to the climax.",
        //   characterDevelopment: "Characters are well-rounded and evolve naturally throughout the story.",
        //   proseQuality: "Fitzgerald's prose is lyrical and evocative, painting vivid pictures with words.",
        //   thematicDepth: "The novel explores themes of wealth, love, and the American Dream with nuance and complexity.",
        // },
      },
      evaluationPending: false,
      authorNGramAnalysis: mockAuthorNGrams,
      authorNGramAnalysisPending: false,
      characterNGramAnalysis: 'TODO',
      characterNGramAnalysisPending: false,
      versionNumber: 1,
      versionId: '1',
      currentStatus: 'bookPending',
    },
  },
  {
    id: '2',
    title: 'To Kill a Mockingbird',
    uploadDate: '2023-09-16T14:45:00Z',
    versions: [[1, '1']],
    mostRecentDetails: {
      author: 'Harper Lee',
      wordCount: 100388,
      chapterCount: 31,
      evaluation: {
        overall: {
          assessment: '',
          score: 50,
          verdict: false,
        },
        // scores: {
        //   plotCoherence: 9.0,
        //   characterDevelopment: 9.5,
        //   proseQuality: 9.0,
        //   thematicDepth: 9.5,
        // },
        // descriptions: {
        //   plotCoherence: "The story unfolds naturally, with subplots that enhance the main narrative.",
        //   characterDevelopment: "Characters are deeply human, with Scout's coming-of-age journey being particularly well-crafted.",
        //   proseQuality: "Lee's writing is clear and evocative, capturing the voice of a young narrator perfectly.",
        //   thematicDepth: "The novel tackles complex themes of racism, injustice, and moral growth with sensitivity and depth.",
        // },
      },
      evaluationPending: true,
      authorNGramAnalysis: mockAuthorNGrams,
      authorNGramAnalysisPending: false,
      characterNGramAnalysis: 'TODO',
      characterNGramAnalysisPending: false,
      versionNumber: 1,
      versionId: '1',
      currentStatus: 'bookPending',
    },
  },
  {
    id: '3',
    title: '1984',
    uploadDate: '2023-09-17T09:15:00Z',
    versions: [[1, '1']],
    mostRecentDetails: {
      author: 'George Orwell',
      wordCount: 88942,
      chapterCount: 23,
      evaluation: {
        overall: {
          assessment: '',
          score: 50,
          verdict: false,
        },
        // scores: {
        //   plotCoherence: 9.0,
        //   characterDevelopment: 8.5,
        //   proseQuality: 9.0,
        //   thematicDepth: 10.0,
        // },
        // descriptions: {
        //   plotCoherence: "The dystopian narrative is chillingly consistent, building tension throughout.",
        //   characterDevelopment: "While Winston's journey is well-portrayed, some secondary characters feel less developed.",
        //   proseQuality: "Orwell's prose is clear and impactful, effectively conveying the bleakness of the world.",
        //   thematicDepth: "The novel's exploration of totalitarianism, surveillance, and the manipulation of truth is unparalleled.",
        // },
      },
      evaluationPending: true,
      authorNGramAnalysis: mockAuthorNGrams,
      authorNGramAnalysisPending: false,
      characterNGramAnalysis: 'TODO',
      characterNGramAnalysisPending: false,
      versionNumber: 1,
      versionId: '1',
      currentStatus: 'bookPending',
    },
  },
];
