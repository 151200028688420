import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducers/rootReducer';

interface AdminProtectedRouteProps {
  redirectPath?: string;
}

const AdminProtectedRoute: React.FC<AdminProtectedRouteProps> = ({
  redirectPath = '/login',
}) => {
  const location = useLocation();
  const isAuthenticated = useSelector(
    (state: RootState) => state.userAuth.isAuthenticated
  );
  const isAdmin = useSelector(
    (state: RootState) => !!state.userAuth.user?.admin
  );

  if (!isAuthenticated || !isAdmin) {
    return <Navigate to={redirectPath} state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default AdminProtectedRoute;
