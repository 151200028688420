import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  updateProfile,
  changePassword,
  deleteAccount,
  logout,
} from '../../store/slices/userAuthSlice';
import { post } from '../../api/apiUtils';
import styles from './AccountSettings.module.css';
import { useAppDispatch } from '../../store';
import OrganizationManagement from '../../components/OrganizationManagement/OrganizationManagement';
import { User } from '../../mocks/handlers';
import { RootState } from '../../store/reducers/rootReducer';

const AccountSettingsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.userAuth.user);
  const [name, setName] = useState(user?.name || '');
  const [email, setEmail] = useState(user?.email || '');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const handleProfileUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    try {
      const response = await post<User>('/users/update-profile', {
        name,
        email,
      });
      dispatch(updateProfile(response));
      setSuccess('Profile updated successfully');
    } catch (err) {
      setError('Failed to update profile');
    }
  };

  const handlePasswordChange = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    if (newPassword !== confirmPassword) {
      setError('New passwords do not match');
      return;
    }
    try {
      await post<{}>('/users/change-password', {
        oldPassword: currentPassword,
        newPassword,
      });
      dispatch(changePassword());
      setSuccess('Password changed successfully');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (err) {
      setError('Failed to change password');
    }
  };

  const handleDeleteAccount = async () => {
    if (
      window.confirm(
        'Are you sure you want to delete your account? This action cannot be undone.'
      )
    ) {
      try {
        await post<{}>('/users/delete-account', {});
        dispatch(deleteAccount());
        await dispatch(logout());
      } catch (err) {
        setError('Failed to delete account');
      }
    }
  };

  return (
    <div className={styles.accountSettingsPage}>
      <h1 className={styles.pageTitle}>Your Account</h1>

      {error && <div className={styles.error}>{error}</div>}
      {!!success && <div className={styles.success}>{success}</div>}

      <section className={styles.section}>
        <h2 className={styles.sectionTitle}>Profile Information</h2>
        <form onSubmit={handleProfileUpdate} className={styles.form}>
          <div className={styles.formGroup}>
            <label htmlFor="name" className={styles.label}>
              Name
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={e => setName(e.target.value)}
              className={styles.input}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="email" className={styles.label}>
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              className={styles.input}
              required
            />
          </div>
          <button type="submit" className={styles.button}>
            Update Profile
          </button>
        </form>
      </section>

      <section className={styles.section}>
        <h2 className={styles.sectionTitle}>Change Password</h2>
        <form onSubmit={handlePasswordChange} className={styles.form}>
          <div className={styles.formGroup}>
            <label htmlFor="currentPassword" className={styles.label}>
              Current Password
            </label>
            <input
              type="password"
              id="currentPassword"
              value={currentPassword}
              onChange={e => setCurrentPassword(e.target.value)}
              className={styles.input}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="newPassword" className={styles.label}>
              New Password
            </label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
              className={styles.input}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="confirmPassword" className={styles.label}>
              Confirm New Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              className={styles.input}
              required
            />
          </div>
          <button type="submit" className={styles.button}>
            Change Password
          </button>
        </form>
      </section>

      <section className={styles.section}>
        <h2 className={styles.sectionTitle}>Delete Account</h2>
        <p className={styles.warningText}>
          Warning: This action cannot be undone.
        </p>
        <button
          onClick={handleDeleteAccount}
          className={`${styles.button} ${styles.deleteButton}`}
        >
          Delete My Account
        </button>
      </section>

      <OrganizationManagement />
    </div>
  );
};

export default AccountSettingsPage;
